const sectionFeedback = async () => {
    $(() => {
      setTimeout(() => {
        const $element = $(".section__feedback");
        if ($element) {
          const $gallery = $element.find(".feedback__list");
          if ($gallery) {
            $gallery.slick({
              arrows: true,
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              rows: 0,
              prevArrow: $element.find(".col__prev .feedback__arrow"),
              nextArrow: $element.find(".col__next .feedback__arrow"),
            });
          }
        }
      }, 1500)
        
    })

};

export default sectionFeedback;