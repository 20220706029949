const sectionNewsletter = () => {
  document.addEventListener("DOMContentLoaded", function () {
    var wpLang = document.querySelector('[name="language"]');
    if (wpLang) {
        var pageLang = document.documentElement.lang;
        var lang = pageLang.split('-');

      wpLang.value = lang[0];
    }
  });
};

export default sectionNewsletter;
