const pageProduct = () => {
  $(() => {
    const $mainSlider = $(".prod_gallery");
    const $thumbnailSlider = $(".prod_gallery_nav");

    if ($mainSlider && $thumbnailSlider) {
      // Initialize main slider
      $mainSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        asNavFor: '.prod_gallery_nav'
      });

      // Initialize thumbnail slider
      $thumbnailSlider.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.prod_gallery',
        dots: false,
        arrows: false,
        // centerMode: true,
        focusOnSelect: true
      });
    }
  });
};

export default pageProduct;
