const pageOsteria = () => {
  bannerLinkEvent(".home__cta");
  bannerLinkEvent("#osteria__banner");
  bannerLinkEvent("#visite__regala");
};

export default pageOsteria;

const bannerLinkEvent = (selector) => {
  const banner = document.querySelector(selector);
  if (banner) {
    const bannerLink = banner.querySelector(".wp-block-button__link");
    if (bannerLink) {
      banner.addEventListener("click", (event) => {
        event.preventDefault(); // Prevent the default link behavior
        if (bannerLink.target === "_blank") {
          window.open(bannerLink.href, bannerLink.target);
        } else {
          window.location.href = bannerLink.href; // Open in the same tab
        }
      });
    }
  }
};
