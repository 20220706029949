const pageAlberti = () => {
  /**
   * Toggler Storia
   */

  const storiaToggler = document.querySelector("#alberti__storia .wp-block-button__link");
  const storiaSections = document.querySelectorAll(".alberti__storia");

  if (storiaToggler && storiaSections.length > 0) {
    albertiSections(storiaToggler, storiaSections);
  }

  /**
   * Toggler Territorio
   */

  const territoriOpen = document.querySelector("#alberti__territori__open");
  const territoriClose = document.querySelector("#alberti__territori__close");
  const territoriTextSection = document.querySelector(".alberti__territori");

  if (territoriOpen && territoriClose && territoriTextSection) {
    territoriOpen.addEventListener("click", () => {
      territoriOpen.classList.add("d-none");
      territoriTextSection.classList.remove("d-none");
    });

    territoriClose.addEventListener("click", () => {
      territoriOpen.classList.remove("d-none");
      territoriTextSection.classList.add("d-none");
    });
  }

  /**
   * Toggler Qualità
   */

  const qualitaToggler = document.querySelector("#qualita .wp-block-button__link");
  const qualitaSection = document.querySelectorAll("#qualita-1");
  if (qualitaToggler && qualitaSection.length > 0) {
    albertiSections(qualitaToggler, qualitaSection);
  }

  /**
   * Toggler Tradizione
   */

  const tradizioneToggler = document.querySelector("#tradizione .wp-block-button__link");
  const tradizioneSection = document.querySelectorAll("#tradizione-1");
  if (tradizioneToggler && tradizioneSection.length > 0) {
    albertiSections(tradizioneToggler, tradizioneSection);
  }
};

export default pageAlberti;

const albertiSections = (toggler, sections) => {
  toggler.addEventListener("click", () => {
    const label = toggler.innerHTML;
    let readMoreLabel = "";
    let readLessLabel = "";

    switch (document.documentElement.lang) {
      case "it-IT":
        readMoreLabel = "Leggi di più";
        readLessLabel = "Leggi di meno";
        break;
      case "en-US":
        readMoreLabel = "Read more";
        readLessLabel = "Read less";
        break;
      case "fr-FR":
        readMoreLabel = "Lire la suite";
        readLessLabel = "Lire la suite";
        break;
      case "de-DE":
        readMoreLabel = "Mehr lesen";
        readLessLabel = "Weniger lesen";
        break;
    }

    toggler.innerHTML = label === readMoreLabel ? readLessLabel : readMoreLabel;
    sections.forEach((section) => {
      section.classList.toggle("d-none");
    });
  });
};
