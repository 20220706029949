import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

const btnWhatsapp = () => {
  document.addEventListener("DOMContentLoaded", () => {
    ScrollTrigger.create({
      trigger: "#footer",
      start: "top bottom", // when the top of the footer enters the bottom of the viewport
      end: "bottom bottom", // when the bottom of the footer leaves the bottom of the viewport
      onEnter: () => document.querySelector(".whatsapp-btn").classList.add("locked"),
      onLeaveBack: () => document.querySelector(".whatsapp-btn").classList.remove("locked"),
    });
  });
};

export default btnWhatsapp;
