const themeQuantityInput = () => {
    $(() => {
        $('form.cart').on('click', 'button.plus, button.minus', function () {
            var $qty = $(this).closest('.quantity').find('.qty');
            var currentVal = parseFloat($qty.val());
            var max = parseFloat($qty.attr('max'));
            var min = parseFloat($qty.attr('min'));
            var step = parseFloat($qty.attr('step'));
    
            if ($(this).is('.plus')) {
                if (max && (currentVal >= max)) {
                    $qty.val(max);
                } else {
                    $qty.val((currentVal + step).toFixed(step.getDecimals()));
                }
            } else {
                if (min && (currentVal <= min)) {
                    $qty.val(min);
                } else if (currentVal > 1) {
                    $qty.val((currentVal - step).toFixed(step.getDecimals()));
                }
            }
    
            $qty.trigger('change');
        });
    })
    
    Number.prototype.getDecimals = function () {
        var num = this,
            match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match) {
            return 0;
        }
        return Math.max(0,
            // Number of digits right of decimal point.
            (match[1] ? match[1].length : 0)
            // Adjust for scientific notation.
            - (match[2] ? +match[2] : 0));
    };
    
}

export default themeQuantityInput