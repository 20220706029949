const pageContacts = () => {
  const contactsPage = document.querySelector(".page-template-template-contacts");
  if (contactsPage) {
    // const checkBoxes = document.querySelectorAll(".wpcf7-acceptance");

    // if (checkBoxes.length > 0) {
    //   checkBoxes.forEach((checkBox) => {
    //     const input = checkBox.querySelector("input");
    //     input.addEventListener("click", () => {
    //       checkBox.classList.toggle("checked");
    //     });
    //   });
    // }

    $(() => {
      const closeFullScreenBtn = document.getElementById("close-full-screen");

      if (closeFullScreenBtn) {
        function checkFullscreen() {
          if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
            closeFullScreenBtn.style.display = "flex";
          } else {
            closeFullScreenBtn.style.display = "none";
          }
        }

        document.addEventListener("fullscreenchange", checkFullscreen);
        document.addEventListener("webkitfullscreenchange", checkFullscreen);
        document.addEventListener("mozfullscreenchange", checkFullscreen);
        document.addEventListener("MSFullscreenChange", checkFullscreen);

        closeFullScreenBtn.addEventListener("click", () => {
          // Exit fullscreen mode
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            // Safari
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            // Firefox
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            // IE/Edge
            document.msExitFullscreen();
          }
        });
      }
    });
  }
};

export default pageContacts;
