const sectionProdPreview = () => {
  $(() => {
    const sliders = $(".section__prod");
    sliders.each(function () {
      if ($(this).hasClass("section__prod__preview")) {
        const $slider = $(this);
        const $ul = $slider.find("ul");
        const $prevArrow = $slider.next().find(".prev-arrow");
        const $nextArrow = $slider.next().find(".next-arrow");

        if ($ul.length) {
          $ul.slick({
            arrows: true,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 0,
            prevArrow: $prevArrow,
            nextArrow: $nextArrow,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });

          const selector = ".wp-block-post-title";
          slideTitlesResize($slider, selector);
          window.addEventListener("resize", () => {
            slideTitlesResize($slider, selector);
          });
        }
      }
    });

    const selector = ".prod-short-desc";
    slideTitlesResize($('.product'), selector);
    window.addEventListener("resize", () => {
      slideTitlesResize($('.product'), selector);
    });
  });
};

export default sectionProdPreview;

/**
 * Resize Slides Titles
 *
 * @param {*} $slider
 * @param {*} selector
 */
const slideTitlesResize = ($slider, selector) => {
  const titles = $slider.find(selector);
  let maxHeight = 0;
  if (titles.length > 0) {
    titles.each(function () {
      const height = $(this).height();
      maxHeight = height > maxHeight ? height : maxHeight;
    });
    titles.each(function () {
      $(this).height(maxHeight);
    });
  }
};
