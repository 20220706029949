const footer = () => {
  const modal = document.getElementById("exampleModal");
  if (modal) {
    document.addEventListener("DOMContentLoaded", function () {
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"));
      myModal.show();
    });
  }
};

export default footer;
