const sectionAccordion = () => {
  const accordions = document.querySelectorAll(".accordion__item");
  if (accordions.length > 0) {
    if (window.innerWidth > 768) {
      // Desktop Accordion Btns
      accordions.forEach((accordion) => {
        const btn = accordion.querySelector(".accordion__btn");
        btn.addEventListener("click", () => {
          accordions.forEach((tab) => {
            if (tab.id === accordion.id) {
              tab.classList.add("show");
            } else {
              tab.classList.remove("show");
            }
          });
        });
      });

      
    } else {
      accordions.forEach((accordion) => {
        accordion.classList.add("show");
      });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        accordions.forEach((accordion) => {
          accordion.classList.add("show");
        });
      } else {
        accordions.forEach((accordion, index) => {
          if (index != 0) {
            accordion.classList.remove("show");
          }
        });
      }
    });
  }
};

export default sectionAccordion;
