const sectionGallery = () => {
  $(() => {
    if ($(".section__gallery").length) {
      $(".section__gallery").each((index, element) => {
        const $gallery = $(element).find(".section__gallery__slider");
        if ($gallery.length) {
          $gallery.slick({
            arrows: true,
            dots: false,
            slidesToScroll: 1,
            cssEase: "cubic-bezier(0.25, 0.1, 0.25, 1)",
            infinite: false,
            initialSlide: 0,
            centerMode: true,
            variableWidth: true,
            prevArrow: $(element).find(".prev-arrow"),
            nextArrow: $(element).find(".next-arrow"),
            rows: 0,
            responsive: [
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  centerMode: false,
                },
              },
            ],
          });

          if ($(element).attr("id") != "osteria__gallery") {
            $gallery.slick("slickSetOption", "centerMode", true, false);
            $gallery.slick("slickSetOption", "centerPadding", "470px", true);
          } else {
            $gallery.slick("slickSetOption", "slidesToShow", 1, true);
          }
        }
      });
    }
  });
};

export default sectionGallery;
