let map;

const initMap = async () => {
  try {
    if (typeof google !== "undefined" && google.maps) {
      const mapElement = document.getElementById("map");
      if (mapElement) {
        await generateMapWithPins(mapElement);
      }
      const fakeMapElement = document.getElementById("fake_map");
      if (fakeMapElement) {
        await getGoogleReviews(fakeMapElement);
      }
    } else {
      console.error("Google Maps API is not loaded.");
    }
  } catch (error) {
    console.error("An error occurred during map initialization:", error);
  }
};

export default initMap;

/*
 * Get Google Reviews
 *
 */

const getGoogleReviews = async () => {
  const $element = $(".section__feedback");
  if ($element && google) {
    const $gallery = $element.find(".feedback__list");
    if ($gallery) {
      var map = new google.maps.Map(document.getElementById("fake_map"), {
        center: {
          lat: -33.8688,
          lng: 151.2195,
        },
        zoom: 13,
      });

      // Create the PlacesService instance
      var service = new google.maps.places.PlacesService(map);
      var request = {
        placeId: "ChIJr5-ZUa8sekcRhUpDYjKr03g",
        fields: ["reviews"],
        reviews_sort: "newest",
      };

      service = new google.maps.places.PlacesService(map);
      service.getDetails(request, (data, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          printReviews($gallery, data);
        }
      });
    }
  }
};

/**
 * Get and Format Reviews data
 *
 * @param {*} $gallery
 * @param {*} result
 */

const printReviews = ($gallery, result) => {
  result.reviews.forEach((review) => {
    // console.log(review)
    let stars = "";
    for (let i = 0; i < review.rating; i++) {
      stars += '<span class="feedback__star"></span>';
    }

    // <img src="${
    // review.profile_photo_url
    // }" alt="${
    // review.author_name
    // }" class="feedback__photo"/>

    let slide = `<div class="feedback__item">
              <div class="feedback__media">
                  <div class="feedback__logo">
    <span>${review.author_name.slice(0, 1).toUpperCase()}</span>
                  </div>
              </div>
              <div class="feedback__content">
                  <h3 class="feedback__name">
                    ${capitalizeFirstLetter(review.author_name.toLowerCase())}
                  </h3>
                  <div class="feedback__stars">
                      ${stars}
                  </div>
                  <div class="feedback__text">
                    ${review.text}
                  </div>
              </div>
            </div>`;

    $gallery.append(slide);
  });
};

/**
 * First letter Uppercase
 *
 * @param {*} string
 * @returns
 */

const capitalizeFirstLetter = (string) => {
  let nameArray = string.split(" ");
  let newName = "";
  nameArray.forEach((word) => {
    let newWord = word.charAt(0).toUpperCase() + word.slice(1);
    newName += newWord + " ";
  });
  return newName;
};

/*
 * Generate Map with pins
 *
 */

const generateMapWithPins = async () => {
  const { Map, InfoWindow } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

  const wrapper = document.querySelector(".page-wrapper");

  // Get Main Pin LatLng
  if (wrapper.getAttribute("data-main-pin")) {
    const latLng = wrapper.getAttribute("data-main-pin").split(" ");
    const location = {
      lat: parseFloat(latLng[0]),
      lng: parseFloat(latLng[1]),
    };

    // Create Map
    const map = new Map(document.getElementById("map"), {
      center: location,
      zoom: 15,
      mapId: "4504f8b37365c3d0",
    });

    // Create Main Pin
    const mainPin = new AdvancedMarkerElement({ map, position: location, title: "La casa del prosciutto" });

    // Create InfoWindow
    const infoWindow = new InfoWindow();

    // Instantiate Geocoder
    const geocoder = new google.maps.Geocoder();

    // Get Parking Pins
    const dataParking = wrapper.getAttribute("data-parking-pin");
    if (dataParking) {
      const pins = dataParking.split("-");
      pins.forEach((pin, i) => {
        const latLng = pin.split(" ");
        if (latLng.length > 1) {
          const markerPos = {
            lat: parseFloat(latLng[0]),
            lng: parseFloat(latLng[1]),
          };

          generatePin(AdvancedMarkerElement, map, markerPos, parkingPinSvgString, "Parcheggio " + (i + 1), infoWindow, geocoder);
        }
      });
    }

    // Get Camping Pins
    const dataCamping = wrapper.getAttribute("data-camping-pin");
    if (dataCamping) {
      const pins = dataCamping.split("-");
      pins.forEach((pin, i) => {
        const latLng = pin.split(" ");
        if (latLng.length > 1) {
          const markerPos = {
            lat: parseFloat(latLng[0]),
            lng: parseFloat(latLng[1]),
          };

          generatePin(AdvancedMarkerElement, map, markerPos, campingPinSvgString, "Camping " + (i + 1), infoWindow, geocoder);
        }
      });
    }
  }
};

/**
 * Generate Marker
 *
 * @param {*} AdvancedMarkerElement
 * @param {*} map
 * @param {*} position
 * @param {*} svgString
 * @param {*} title
 * @param {*} infoWindow
 * @param {*} geocoder
 * @returns
 */

const generatePin = async (AdvancedMarkerElement, map, position, svgString, title, infoWindow, geocoder) => {
  const parser = new DOMParser();
  const pinElement = new AdvancedMarkerElement({
    map,
    position,
    content: parser.parseFromString(svgString, "image/svg+xml").documentElement,
    title,
    gmpClickable: true,
  });

  const address = await getAddress(position, geocoder);

  pinElement.addListener("click", ({ domEvent }) => {
    const { target } = domEvent;
    const markerPosition = pinElement.position;

    infoWindow.close();
    infoWindow.setContent(`
            <div class="info-window">
              <h3>${title}</strong></h3>
              <p>${address}</p>
              <footer><a href="https://www.google.com/maps/search/?api=1&query=${markerPosition.lat},${markerPosition.lng}" target="_blank">Visualizza su Google Maps</a></footer>
            </div>
          `);
    infoWindow.open(pinElement.map, pinElement);
  });

  return pinElement;
};

/**
 * Function to reverse geocode and get address
 *
 * @param {*} latLng
 * @param {*} geocoder
 * @returns
 */

const getAddress = async (latLng, geocoder) => {
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        location: latLng,
      },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            resolve(results[0].formatted_address);
          } else {
            resolve("No address found");
          }
        } else {
          reject("Geocoder failed due to: " + status);
        }
      }
    );
  });
};

/**
 * Parking Icon SVG
 */

const parkingPinSvgString = `
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Raggruppa_209" data-name="Raggruppa 209" transform="translate(128 -10258.36)">
    <rect id="Rettangolo_225" data-name="Rettangolo 225" width="40" height="40" rx="5" transform="translate(-128 10258.36)" fill="#6f1411"/>
    <path id="Tracciato_1585" data-name="Tracciato 1585" d="M5.349-10.491H3.359V-24.31H8.526a5.161,5.161,0,0,1,2.342.5A3.811,3.811,0,0,1,12.434-22.4,3.928,3.928,0,0,1,13-20.281a3.915,3.915,0,0,1-.562,2.107,3.836,3.836,0,0,1-1.567,1.414,5.123,5.123,0,0,1-2.342.508H5.156v-1.72h3.37a2.5,2.5,0,0,0,1.794-.62,2.219,2.219,0,0,0,.652-1.69,2.213,2.213,0,0,0-.652-1.7,2.523,2.523,0,0,0-1.794-.61H5.349Z" transform="translate(-115.238 10295.776)" fill="#fff"/>
    </g>
</svg>`;

/**
 * Camping Icon Svg
 */

const campingPinSvgString = `
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Raggruppa_278" data-name="Raggruppa 278" transform="translate(-1295 -2071)">
    <g id="Raggruppa_213" data-name="Raggruppa 213" transform="translate(1423 -8187.36)">
        <rect id="Rettangolo_225" data-name="Rettangolo 225" width="40" height="40" rx="5" transform="translate(-128 10258.36)" fill="#6f1411"/>
    </g>
    <g id="Raggruppa_217" data-name="Raggruppa 217" transform="translate(1303 2084)">
        <g id="Raggruppa_218" data-name="Raggruppa 218" transform="translate(0 0)">
        <path id="Tracciato_1591" data-name="Tracciato 1591" d="M64.884,13.388l1.974,3.638,1.211.314a1.46,1.46,0,0,1,.759.46,2.045,2.045,0,0,1,.24,1.181v2.532H66.723" transform="translate(-45.501 -9.388)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Tracciato_1592" data-name="Tracciato 1592" d="M15.853,13.035V4.6h7.068c1.424,0,1.017-1.235,1.017-1.235C23.328,1.1,19.62,1.255,19.62,1.255H2.35s-1.1-.136-1.1,1.187v9.52a.994.994,0,0,0,1.125,1.074H4.854" transform="translate(-0.877 -0.877)" fill="none" stroke="#fff" stroke-width="1"/>
        <line id="Linea_98" data-name="Linea 98" x2="10.272" transform="translate(7.228 12.133)" fill="none" stroke="#fff" stroke-width="1"/>
        <path id="Tracciato_1593" data-name="Tracciato 1593" d="M61.143,19.351H56.659V15.544" transform="translate(-39.733 -11.739)" fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="1"/>
        <path id="Tracciato_1594" data-name="Tracciato 1594" d="M12.8,37.291a1.629,1.629,0,1,0,1.63-1.629A1.629,1.629,0,0,0,12.8,37.291Z" transform="translate(-8.978 -25.008)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Tracciato_1595" data-name="Tracciato 1595" d="M59.958,37.291a1.629,1.629,0,1,0,1.63-1.629A1.629,1.629,0,0,0,59.958,37.291Z" transform="translate(-42.046 -25.008)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Tracciato_1597" data-name="Tracciato 1597" d="M9.677,12.63v3.121h4.1V12.63h-4.1" transform="translate(-6.786 -8.857)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        </g>
    </g>
    </g>
</svg>`;
